import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Breadcrumb from './Breadcrumb';
import { ClipLoader } from 'react-spinners'; // Import a spinner library (like react-spinners or any other)

const backend_url = "https://drive-navigator-914312986344.us-central1.run.app";
// const backend_url = "http://localhost:5000";

function App() {
  const [user, setUser] = useState(null);
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [folderPath, setFolderPath] = useState([{ id: 'root', name: 'Root' }]);  // State for breadcrumb navigation
  const [currentView, setCurrentView] = useState('browsing'); // Tracks the current view
  const [notification, setNotification] = useState(null); // State for notification
  const [toggleScanLoading, setToggleScanLoading] = useState({}); // State to track toggle scan loading
  const placeholderImage = 'https://via.placeholder.com/180';  // Placeholder for files without thumbnails

  useEffect(() => {
    axios.get(`${backend_url}/api/profile`, { withCredentials: true })
      .then(response => {
        setUser(response.data);
      })
      .catch(() => {
        setUser(null);
      });
  }, []);

  const fetchFilesAndFolders = (folderId = 'root') => {
    axios.get(`${backend_url}/api/files?folderId=${folderId}`, { withCredentials: true })
      .then(response => {
        const filesData = response.data;
        const folders = filesData.filter(file => file.mimeType === 'application/vnd.google-apps.folder');
        const regularFiles = filesData.filter(file => file.mimeType !== 'application/vnd.google-apps.folder');

        setFolders(folders);
        setFiles(regularFiles);
      })
      .catch(err => {
        console.error('Error fetching files', err);
      });
  };

  useEffect(() => {
    fetchFilesAndFolders();
  }, []);

  const handleLogin = () => {
    window.open(`${backend_url}/auth/google`, '_self');
  };

  const handleLogout = () => {
    window.open(`${backend_url}/logout`, '_self');
  };

  const handleFolderClick = (folderId, folderName) => {
    setFolderPath([...folderPath, { id: folderId, name: folderName }]);
    fetchFilesAndFolders(folderId);
  };

  const handleBreadcrumbClick = (index) => {
    const selectedFolder = folderPath[index];
    setFolderPath(folderPath.slice(0, index + 1));
    fetchFilesAndFolders(selectedFolder.id);
  };

  const showNotification = (message, type = 'success') => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  };

  const toggleWeeklyScan = (folderId, status) => {
    setToggleScanLoading(prev => ({ ...prev, [folderId]: true }));
    axios.post(`${backend_url}/api/toggle-scan`, { folderId, status }, { withCredentials: true })
      .then(() => {
        const updatedFolders = folders.map(folder =>
          folder.id === folderId ? { ...folder, scanOnOff: status } : folder
        );
        setFolders(updatedFolders);
        showNotification(`Weekly scan ${status ? 'enabled' : 'disabled'} successfully.`);
      })
      .catch((err) => {
        console.error('Error toggling weekly scan:', err);
        showNotification('Failed to toggle weekly scan.', 'danger');
      }).finally(() => {
        // Set loading to false for this folder after the request finishes
        setToggleScanLoading(prev => ({ ...prev, [folderId]: false }));
      });
      
  };

  const scanNow = (folderId) => {
    axios.post(`${backend_url}/api/trigger-scan`, { folderId }, { withCredentials: true })
      .then(response => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let formattedLastScan = `${year}-${month}-${day} ${hours}:${minutes}`;

        const updatedFolders = folders.map(folder =>
          folder.id === folderId ? { ...folder, lastScan: formattedLastScan } : folder
        );
        setFolders(updatedFolders);
        showNotification(`Scan Triggered successfully.`);

      })
      .catch(err => {
        console.error('Error scanning now:', err);
        showNotification(`Error scanning now.`);
      });
  };

  const toggleView = () => {
    setCurrentView(currentView === 'browsing' ? 'configuration' : 'browsing');
  };


  const handleDownloadFile = (fileId) => {
    // Create a link element
    const link = document.createElement('a');
    link.href = `${backend_url}/api/download-file?fileId=${fileId}`;
    // link.target = '_blank';
    link.rel = 'noopener noreferrer';
  link.setAttribute('download', '');

    // Append link to body and trigger click to initiate download
    document.body.appendChild(link);
    link.click();
    
    // Clean up by removing the link element
    document.body.removeChild(link);
  };


  const handleProcessFile = (fileId) => {
    const updatedFiles = files.map(file =>
      file.id === fileId ? { ...file, loading: true } : file
    );
    setFiles(updatedFiles);

    axios.post(`${backend_url}/api/process-file`, { fileId }, { withCredentials: true })
      .then(response => {
        const updatedFiles = files.map(file =>
          file.id === fileId ? { ...file, loading: false, result: response.data.result } : file
        );
        setFiles(updatedFiles);
      })
      .catch(err => {
        console.error('Error processing file', err);
        const updatedFiles = files.map(file =>
          file.id === fileId ? { ...file, loading: false } : file
        );
        setFiles(updatedFiles);
      });
  };

  return (
    <div className="container-fluid min-vh-100 d-flex flex-column align-items-center p-4 bg-light">
      {user ? (
        <button className="btn btn-danger mb-4 mx-2 position-absolute top-0 end-0 mt-3 me-3" onClick={handleLogout}>
          Logout
        </button>
      ) : null}
      <h1 className="display-4 mb-5">Image Navigator</h1>
      {/* Notification */}
      {notification && (
        <div 
          className={`alert alert-${notification.type}`} 
          role="alert" 
          style={{ 
            position: 'fixed', 
            bottom: '20px', 
            right: '20px', 
            zIndex: 9999, 
            fontSize: '1.3em' // Increase font size for larger appearance
          }}
        > 
          {notification.message}
        </div>
      )}
      {user ? (
        <>
        <button className="btn btn-primary mb-4" onClick={toggleView}>
          {currentView === 'browsing' ? 'Switch to Configuration View' : 'Switch to Browsing View'}
        </button>
        {currentView === 'browsing' ? (
        <div className="w-100 max-w-6xl">
          
          {folderPath.length > 1 && (
                <button
                  className="btn btn-primary mb-4 "
                  onClick={() => scanNow(folderPath[folderPath.length-1].id)}
                >
                  Scan <b><i>{folderPath[folderPath.length-1].name}</i></b> Now
                </button>
              )}
          <Breadcrumb folderPath={folderPath} onNavigate={handleBreadcrumbClick} />
          
          {/* Folders Section */}
          <div>
            { folders.length > 1 && <h3 className="h5">Folders</h3>}
            <div className="row">
              
              {folders.map(folder => (
                <div key={folder.id} className="col-md-3 p-2" >
                  <div
                    className="card text-center p-3 shadow-sm hover-shadow cursor-pointer"
                    onClick={() => handleFolderClick(folder.id, folder.name)}
                  >
                    <span className="text-primary">{folder.name}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Files Section */}
          <div className="row row-cols-3 gx-2 gy-2">
            {files.map(file => (
              <div key={file.id}>
                <div className="card shadow-sm">
                  <img
                    src={file.thumbnail ? file.thumbnail : placeholderImage}
                    alt={file.name}
                    className="card-img-top"
                    style={{ height: '240px', objectFit: 'cover', width: '100%' }}  // Ensures fixed height and covers the area
                  />

                  <div class="card-body">
                      <h6 class="card-title d-flex align-items-center">
                        <button
                          onClick={(event) => handleProcessFile(file.id, event)}
                          className="btn btn-light mr-1">
                          🔗
                        </button>
                        <button
                          onClick={() => handleDownloadFile(file.id)}
                          className="btn btn-light mr-1"
                        >
                          📥
                        </button> 
                        <span className="text-truncate flex-grow-1">{file.name}</span>

                      </h6>
                      <p class="card-text">{file.loading && (
                      <div className="d-flex justify-content-center">
                        <ClipLoader size={20} color={"#123abc"} loading={true} />
                      </div>
                    )}
                    {file.result && (
                      <div>
                         <button
                          onClick={() => navigator.clipboard.writeText(file.result)}
                          className="btn btn-light align-middle mr-2"
                        >
                          📋
                        </button>
                        
                        <span className="text-success text-truncate d-inline-block w-75 align-middle">{file.result}</span>
                       
                      </div>
                    )}
                    </p>
                    </div>
                </div>
              </div>
            ))}
          </div>
        </div>):(

         // Folders Configuration View
            <div className="w-100 max-w-6xl">
              <h3>Folders Configuration</h3>
              <div className="row">
                {folders.map(folder => (
                  <div key={folder.id} className="col-md-3 p-2">
                    <div className="card shadow-sm p-3">
                      <h5>{folder.name}</h5>
                      <p>Last Scanned: {folder.lastScan || 'Never'}</p>
                      <div className="d-flex justify-content-between">
                        <button
                          className={`m-1 btn ${folder.scanOnOff ? 'btn-success' : 'btn-secondary'}`}
                          onClick={() => toggleWeeklyScan(folder.id, !folder.scanOnOff)}
                          disabled={toggleScanLoading[folder.id]} // disable button while loading
                        >

                          {toggleScanLoading[folder.id] ? (
                            <ClipLoader size={15} color={"#fff"} loading={true} />
                          ) : (
                            folder.scanOnOff ? 'Disable Weekly Scan' : 'Enable Weekly Scan'
                          )}
                        </button>
                        <button className=" m-1 btn btn-primary" onClick={() => scanNow(folder.id)}>
                          Scan Now
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <button className="btn btn-primary" onClick={handleLogin}>
          Login with Google
        </button>
      )}   
    </div>
  );
}

export default App;
